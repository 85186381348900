import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("authentications/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.messages);
          console.log(state.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  // [REGISTER](context, credentials) {
  //   return new Promise(resolve => {
  //     ApiService.post("login", credentials)
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
          
  //       });
  //   });
  // },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken() && JwtService.getToken() != undefined) {
      ApiService.setHeader();
      ApiService.post("authentications/token-validation",{token: JwtService.getToken()})
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          context.commit(PURGE_AUTH);
        });
        
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  // [UPDATE_PASSWORD](context, payload) {
  //   const password = payload;

  //   return ApiService.put("password", password).then(({ data }) => {
  //     context.commit(SET_PASSWORD, data);
  //     return data;
  //   });
  // }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
      state.isAuthenticated = true;
      state.user = user;
      state.errors = {}; 
    if(state.user.token != undefined) {
      JwtService.saveToken(state.user.token);
    }
    
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
